import React, { useEffect } from "react";
import pageImage from "images/HomepageLogo.png";
import styled from "styled-components";
import { Col, Row } from "reactstrap";
import { FaChevronRight } from "react-icons/fa";
import { FullBackground, Layout, PageContainer, SEO } from "components";
import { device, routes } from "utils";
import { navigate } from "gatsby";

const RequestSentPage = ({ location }) => {
  // Check if a form was sent. If not, that means the user navigated to this url directly, so we
  // would like to redirect them back to the Contact Us page
  // Note: useEffect throws error in console in local dev, but is fine in compiled preview/prod.
  useEffect(() => {
    if (!location.state?.formSent) {
      console.log("requestSent redirect to contactUs");
      navigate(routes("contactUs"), { replace: true });
    }
  }, [location.state]);
  const title = "Request Sent";

  return (
    <FullBackground gradient="black">
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `_linkedin_partner_id = "3729265";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);
            </script>
            <script type="text/javascript">
              (function(l) {
              if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
              window.lintrk.q=[]}
              var s = document.getElementsByTagName("script")[0];
              var b = document.createElement("script");
              b.type = "text/javascript";b.async = true;
              b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
              s.parentNode.insertBefore(b, s);})(window.lintrk);
            </script>`,
        }}
      />
      <Layout clearNav clearFooter location={location}>
        <SEO image={pageImage} title={title} uri={location.pathname} />
        <PageContainer>
          <Row>
            <Col
              sm={12}
              md={{ size: 8, offset: 2 }}
              lg={{ size: 6, offset: 3 }}
            >
              <S.RequestSentPageContainer>
                <S.Header>Request Sent</S.Header>
                <S.Text>
                  Your contact request has been received, we will be in touch
                  shortly.
                  <br />
                  Thank you for your interest!
                </S.Text>
                <S.Link
                  className="sm hover-underline tour"
                  href={routes("virtualTour")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Take a virtual tour
                  <S.RightArrowIcon />
                </S.Link>
                <S.Link
                  className="sm hover-underline instrument"
                  href={routes("instrumentation")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Scientific Instrumentation
                  <S.RightArrowIcon />
                </S.Link>
              </S.RequestSentPageContainer>
            </Col>
          </Row>
        </PageContainer>
      </Layout>
    </FullBackground>
  );
};

export default RequestSentPage;

const S = {};

S.RequestSentPageContainer = styled.div`
  margin-bottom: 15rem;
  margin-top: 2rem;
  color: #ffffff;
  @media ${device.md} {
    margin-top: 4.8rem;
  }
`;

S.Header = styled.div`
  font-size: 2.4rem;
  font-weight: 500;
  margin-bottom: 3rem;
`;

S.Text = styled.div`
  font-size: 1.7rem;
  font-weight: 300;
  margin-bottom: 3rem;
`;

S.Link = styled.a`
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  align-items: center;
  &.tour {
    width: 15.5rem;
  }
  &.instrument {
    width: 25rem;
  }
`;

S.RightArrowIcon = styled(FaChevronRight)`
  background-color: transparent;
  color: ${({ theme }) => theme.greenLink};
  padding: 0.2rem;
  height: 16px;
`;
